<template>
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus title="Fleets" :hidePlus="true" class="mb-10" />
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="px-4 vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>{{ item.id | onlyLastFive }}</fsTableRowItem>
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewFleetProfile',
                  params: { id: item.id },
                }"
              >
                {{ item.name }}
              </router-link>
            </fsTableRowItem>
            <fsTableRowItem>{{ item.address }}</fsTableRowItem>
            <fsTableRowItem>{{ item.address }}</fsTableRowItem>
            <fsTableRowItem>
              <x-status
                :variant="item.visibility ? 'green' : 'orange'"
                :text="item.visibility ? 'Public' : 'Private'"
                profile="fleet"
              />
            </fsTableRowItem>
            <fsTableRowItem>{{ item.email_address || '--' }}</fsTableRowItem>
            <fsTableRowItem>{{
              item.created_at | friendlyDate
            }}</fsTableRowItem>
            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :currentVal="item.visibility"
                  onOffMethod="PATCH"
                  :onOffEndpoint="getActionsEndpoint(item.id)"
                  actionOnKey="visibility"
                  :actionOnVal="true"
                  @actionOn="item.visibility = true"
                  actionOffKey="visibility"
                  :actionOffVal="false"
                  @actionOff="item.visibility = false"
                  :actionDeleteEnabled="false"
                  actionDeleteKey="deleted_at"
                  actionDeleteVal="now"
                  actionDeleteMethod="PATCH"
                  :actionDeleteEndpoint="getActionsEndpoint(item.id)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
    </content-section>
  </div>
</template>

<script>
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
// FsTable UI
import fsTableUiMixin from '@/mixins/fsTableUiMixin'

export default {
  name: 'ViewOrganizationUserFleets',
  mixins: [
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    ExportAsDropdown: () => import('@/components/dropdown/ExportAsDropdown'),
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    OtoSearch: () => import('@/components/ui/OtoSearch'),
    // AddEditFleet: () => import('@/views/fleet/AddEditFleet.vue'),
    XStatus: () => import('@/components/badge/XStatus'),
    MoreActionsDropdown: () =>
      import('@/components/dropdown/MoreActionsDropdown'),
  },
  data() {
    return {
      isLoading: false,

      indexDataEndpoint: this.$config.fleet.api.index,
      queryStringOptions: {
        prepend: '?',
        append: `&org_user=${this.$route.params.id}`,
      },
      tableHeaders: [
        {
          name: 'Fleet ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Fleet Name',
          width: '15%',
          field: 'name',
          sort: true,
          order: '',
        },
        {
          name: 'Location',
          width: '15%',
          field: 'address',
          sort: true,
          order: '',
        },
        {
          name: 'Visibility',
          width: '15%',
          field: 'visibility',
          sort: null,
          order: '',
        },
        {
          name: 'Support Email',
          width: '15%',
          field: 'email_address',
          sort: true,
          order: '',
        },
        {
          name: 'Date Created',
          width: '20%',
          field: 'created_at',
          sort: true,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
      is_edit: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
  methods: {
    getActionsEndpoint(id) {
      return this.$config.fleet.api.update(id)
    },
  },
}
</script>

<style lang="scss"></style>
